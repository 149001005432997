import { authenticationRepositoryProvider, userWebRepositoryProvider } from '@module/user';
import { Module } from '@nestjs/common';
import { useCaseProviders } from './usecase-providers';
import {
  AssignmentMapper,
  assignmentRepositoryProvider,
  examinationSubmissionRepositoryProvider,
  homeworkSubmissionRepositoryProvider,
  participatedAssignmentRepositoryProvider,
  practiceSubmissionRepositoryProvider,
  submissionRepositoryProvider,
} from '@module/assignment';
import { apiProviders } from './api-providers';
import {
  FormReleaseMapper,
  formReleaseWebRepositoryProvider,
  questionReleasesWebRepositoryProvider,
} from '@module/form';
import { cachedProviders } from './cached-providers';
import {
  externalFileStorageRepositoryProvider,
  FileStorageMapper,
  fileStorageRepositoryProvider,
} from '@module/file-storage';
@Module({
  providers: [
    ...cachedProviders,
    ...apiProviders,
    authenticationRepositoryProvider,
    assignmentRepositoryProvider,
    userWebRepositoryProvider,
    formReleaseWebRepositoryProvider,
    questionReleasesWebRepositoryProvider,
    submissionRepositoryProvider,
    participatedAssignmentRepositoryProvider,
    fileStorageRepositoryProvider,
    externalFileStorageRepositoryProvider,
    FileStorageMapper,
    AssignmentMapper,
    practiceSubmissionRepositoryProvider,
    homeworkSubmissionRepositoryProvider,
    examinationSubmissionRepositoryProvider,
    FormReleaseMapper,
    ...useCaseProviders,
  ],
  exports: [...useCaseProviders],
})
export class AppModule {}
