import {
  SubmissionWebCached,
  SUBMISSION_WEB_CACHED,
  ESSAY_ANSWER_DRAFT_WEB_CACHED,
  EssayAnswerDraftWebCached,
  AssignmentInMemoryWebCached,
  ASSIGNMENT_CACHED_REPOSITORY,
} from '@module/assignment';
import {
  QuestionReleaseInMemoryCached,
  QUESTION_RELEASE_CACHED_REPOSITORY,
  FORM_RELEASE_CACHED_REPOSITORY,
  FormReleaseInMemoryWebCached,
} from '@module/form';

export const cachedProviders = [
  {
    provide: QUESTION_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new QuestionReleaseInMemoryCached();
    },
  },
  {
    provide: SUBMISSION_WEB_CACHED,
    useFactory: () => {
      return new SubmissionWebCached();
    },
  },
  {
    provide: ASSIGNMENT_CACHED_REPOSITORY,
    useFactory: () => {
      return new AssignmentInMemoryWebCached();
    },
  },
  {
    provide: FORM_RELEASE_CACHED_REPOSITORY,
    useFactory: () => {
      return new FormReleaseInMemoryWebCached();
    },
  },
  {
    provide: ESSAY_ANSWER_DRAFT_WEB_CACHED,
    useFactory: () => {
      return new EssayAnswerDraftWebCached();
    },
  },
];
