import { Action, RequestStatus, createReducer } from '@lib/plugin-redux-core';
import {
  GET_ASSIGNMENTS_FOR_RESPONDER_USE_CASE,
  GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
  HomeworkAssignment,
  ParticipatedAssignmentResponder,
  PracticeAssignment,
} from '@module/assignment';
import { UseCaseError } from '@digibricks/domains';
import { handlerGetAssignmentsForResponder } from './dashboard.handler';

export type GetParticipatedAssignmentResponderUseCaseState = {
  participatedAssignmentResponse: ParticipatedAssignmentResponder[];
  isParticipatedAssignmentLoading: boolean;
};

export type GetAssignmentsForResponderUseCaseState = {
  assignments: (HomeworkAssignment | PracticeAssignment)[];
  getAssignmentsForResponderStatus: RequestStatus;
};

export type ErrorState = {
  error?: UseCaseError<any>;
};

export type DashboardState = GetParticipatedAssignmentResponderUseCaseState &
  ErrorState &
  GetAssignmentsForResponderUseCaseState;

export const initialState: ErrorState = {
  error: null,
};

export const dashboardReducer = createReducer(initialState, [
  {
    name: GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
    executing: (state: DashboardState): DashboardState => {
      return {
        ...state,
        isParticipatedAssignmentLoading: true,
      };
    },
    success: (state: DashboardState, action: Action): DashboardState => {
      const { payload } = action;

      return {
        ...state,
        participatedAssignmentResponse: payload as ParticipatedAssignmentResponder[],
        isParticipatedAssignmentLoading: false,
      };
    },
    error: (state: DashboardState, action: Action): DashboardState => {
      const { error } = action;

      return {
        ...state,
        error,
        isParticipatedAssignmentLoading: false,
      };
    },
  },
  {
    name: GET_ASSIGNMENTS_FOR_RESPONDER_USE_CASE,
    ...handlerGetAssignmentsForResponder,
  },
]);
